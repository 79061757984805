module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-70dd4be6c4/0/cache/gatsby-plugin-catch-links-npm-5.11.0-968e140504-69abe340e2.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-a494c55d53/0/cache/gatsby-plugin-manifest-npm-5.11.0-857fd9ff94-785b9c333d.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#FFFFFF","display":"standalone","icon":"src/images/favicon.ico","name":"Alysia Lynn","start_url":"/","theme_color":"#6E00FF","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6f09b4bd5778216b8b05f98f17fd1f17"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-e14972c2b9/0/cache/gatsby-plugin-styled-components-npm-6.11.0-c502b4e601-47de243cc4.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-373724406b.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-75},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-9f98b43e72/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
