const factsAboutMe = [
  'build web and mobile apps!',
  'am passionate about tech diversity!',
  'overuse exclamation marks!',
  'will never have enough plants!',
  "can't stop coding!",
  'believe in space cats!',
];

export default factsAboutMe;
